import { InputControlInformation } from "../internal/common-models";
import { PaginationViewModel } from "../internal/pagination.viewmodel";

export class BaseViewModel {
    PageTitle: string = '';
    PageNo?: number = 0;
    PageSize?: number = 10;
    FormSubmitted?: boolean;
    controlsInformation?: { [key: string]: InputControlInformation }
    pagination: PaginationViewModel = { PageNo: 1, PageSize: 10, totalCount: 0, totalPages: [] }
}



